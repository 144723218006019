import React, { useState, FC } from "react";

// =================== Component imports ======================

import Carousel from "./carousel/carousel";
import ProductText from "./product-text/product-text";

// =================== Styles imports =========================
import style from "./product-info.module.scss";

const ProductInfo: FC = () => {
  return (
    <section className={` ${style.section}`}>
      <div className={style.productImgSection}>
        <Carousel />
      </div>
      <div className={style.productInfo}>
        <ProductText />
      </div>
    </section>
  );
};

export default ProductInfo;
