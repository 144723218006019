import React, { FC, useEffect, useContext } from "react";

// ================== Components =======================
import PageLayout from "../components/layout/page-layout";
import ProductInfo from "../components/products/product-info";
import { PageContext, pages } from "../context/page-context";

import leftCloud from "../images/svg/LeftCloudBlue.svg";
import rightCloud from "../images/svg/RightCloudBlue.svg";

// ================== Styles ===========================
import style from "../styles/page-styles/product-page.module.scss";

const ProductPage: FC = () => {
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.product);
  }, [pageContext.currentPage]);
  return (
    <PageLayout>
      <section className={style.pageContent}>
        <img className={style.rightCloud} src={rightCloud} alt="cloud background" />
        <img className={style.leftCloud} src={leftCloud} alt="cloud background" />
        <ProductInfo />
      </section>
    </PageLayout>
  );
};

export default ProductPage;
