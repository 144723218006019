import React, { useState, FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTransition, animated } from "react-spring";

// ================== Helper functions =======================
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";

import IconButton from "@material-ui/core/IconButton";
import { FiArrowRightCircle, FiArrowLeftCircle } from "react-icons/fi";

import * as style from "./carousel.module.scss";

type ImageArrayType = {
  asset: {
    _id: string;
  };
  crop: any;
  hotspot: any;
};

type CarouselQuery = {
  sanityProduct: {
    image: Array<ImageArrayType>;
  };
};

const Carousel: FC = () => {
  const { sanityProduct: product }: CarouselQuery = useStaticQuery(productImgQuery);

  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isRight, setRight] = useState<boolean>();
  const nextImage = (): void => {
    setRight(true);
    let newIndex = (photoIndex + 1) % product.image.length;
    setPhotoIndex(newIndex);
  };
  const prevImage = (): void => {
    setRight(false);
    let newIndex = (photoIndex - 1 + product.image.length) % product.image.length;
    setPhotoIndex(newIndex);
  };
  const selectImage = (imageIndex: number): void => {
    setPhotoIndex(imageIndex);
  };

  const transitions = useTransition(photoIndex, (p) => p, {
    from: { opacity: 0, transform: `translate3d(${isRight ? "100%" : "-100%"},0,0)` },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: `translate3d(${isRight ? "-50%" : "50%"},0,0)` },
    initial: { opacity: 0, transform: "translate3d(0%,0,0)" },
  });

  return (
    <div className={style.carouselContainer}>
      <div className={style.mainImageContainer}>
        {transitions.map(({ item, props, key }) => {
          const photo = product.image[item];
          return (
            <animated.img
              key={key}
              style={props}
              className={`${style.carouselImage}`}
              src={imageUrlFor(buildImageObj(photo)).height(550).fit("crop").url()}
            />
          );
        })}
        {/* {product.image.map((currentImage: any, index: number) => (
          <img
            key={currentImage.asset._id}
            className={`${style.carouselImage} ${photoIndex === index ? style.isActive : ""}`}
            src={imageUrlFor(buildImageObj(currentImage)).height(550).fit("crop").url()}
          />
        ))} */}
        <IconButton className={style.btnNext} onClick={nextImage}>
          <FiArrowRightCircle size="3rem" />
        </IconButton>
        <IconButton className={style.btnPrev} onClick={prevImage}>
          <FiArrowLeftCircle size="3rem" />
        </IconButton>
      </div>

      <div className={`${style.thumbnailContainer}`}>
        {product.image.map((currentImage: ImageArrayType, index: number) => (
          <img
            key={currentImage.asset._id}
            onClick={() => selectImage(index)}
            className={`${style.thumbnailImg} ${
              photoIndex === index ? style.isThumbnailActive : ""
            }`}
            src={imageUrlFor(buildImageObj(currentImage)).width(120).fit("crop").url()}
          />
          // <img
          //   src={currentImage.asset.metadata.lqip}
          //   key={currentImage.asset._id}
          //   onClick={() => selectImage(index)}
          //   className={`${style.thumbnailImg} ${
          //     photoIndex === index ? style.isThumbnailActive : ""
          //   }`}
          // />
          // <Img
          //   fluid={currentImage.asset.childImageSharp.fluid} //   key={currentImage.asset._id}
          //   onClick={() => selectImage(index)}
          //   className={`${style.thumbnailImg} ${
          //     photoIndex === index ? style.isThumbnailActive : ""
          //   }`}
          // />
        ))}
      </div>
    </div>
  );
};

export default Carousel;

export const productImgQuery = graphql`
  query productImg {
    sanityProduct {
      image {
        asset {
          _id
        }
        crop {
          _key
          _type
          bottom
          left
          right
          top
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
      }
    }
  }
`;
